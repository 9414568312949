import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie';

import './DragHint.scss';

const DragHint = () => {
  const [isVisible, setIsVisible] = useState(false);

  const dragDetected = useRef(false);

  const dragRef = useRef(null);

  // See: https://joshwcomeau.com/react/the-perils-of-rehydration
  // Only render this component AFTER rehydration
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);

  const handleMouseMove = e => {
    if (dragRef.current) {
      dragRef.current.style.top = `${e.clientY}px`;
      dragRef.current.style.left = `${e.clientX}px`;
    }
  };

  const handleMouseDown = () => {
    Cookies.set('drag-hinted', '1', { expires: 30 });
    setIsVisible(false);
    document.removeEventListener('mousemove', handleMouseMove);
    dragDetected.current = true;
  };

  useEffect(() => {
    if (Cookies.get('drag-hinted')) {
      Cookies.set('drag-hinted', '1', { expires: 30 }); // refresh
      return;
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('touchstart', handleMouseDown);
    setTimeout(() => {
      if (dragDetected.current) return;

      setIsVisible(true);

      setTimeout(() => {
        Cookies.set('drag-hinted', '1', { expires: 30 });
        setIsVisible(false);
      }, 10000);
    }, 3000);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchstart', handleMouseMove);
    };
  }, []);

  // Only render this component AFTER rehydration
  if (!hasMounted) {
    return null;
  }

  if (Cookies.get('drag-hinted')) return null;

  return (
    <div
      className={`drag-hint ${isVisible ? 'is-visible' : ''}`}
      ref={dragRef}
      style={{ top: '50%', left: '50%' }}
    >
      <svg
        width="73"
        height="73"
        viewBox="0 0 73 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="59.5" cy="36.5" r="1.5" fill="white" />
        <path d="M73 36.5L66.25 40.3971L66.25 32.6029L73 36.5Z" fill="white" />
        <circle cx="13.5" cy="36.5" r="1.5" fill="white" />
        <path d="M0 36.5L6.75 32.6029L6.75 40.3971L0 36.5Z" fill="white" />
        <circle cx="36.5" cy="58.5" r="1.5" fill="white" />
        <path d="M36.5 73L32.6029 66.25H40.3971L36.5 73Z" fill="white" />
        <circle cx="36.5" cy="14.5" r="1.5" fill="white" />
        <path
          d="M36.5 -7.86805e-07L40.3971 6.75L32.6029 6.75L36.5 -7.86805e-07Z"
          fill="white"
        />
      </svg>
      <div className="drag-hint-text">DRAG</div>
    </div>
  );
};

export default DragHint;
