import React from 'react';

import ArrowUp from '../../images/arrow-up.svg';
import ArrowRight from '../../images/arrow-right.svg';
import ArrowDown from '../../images/arrow-down.svg';
import ArrowLeft from '../../images/arrow-left.svg';

const Arrow = ({
  up = false,
  right = false,
  down = false,
  left = false,
  ...rest
}) => {
  if (up) return <ArrowUp {...rest} />;
  if (right) return <ArrowRight {...rest} />;
  if (down) return <ArrowDown {...rest} />;
  if (left) return <ArrowLeft {...rest} />;

  console.error('Still using the old arrows :/');

  return null;

  // return (
  //   <svg
  //     width="16"
  //     height="61"
  //     viewBox="0 0 16 61"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       d="M7.29289 60.7071C7.68342 61.0976 8.31658 61.0976 8.70711 60.7071L15.0711 54.3431C15.4616 53.9526 15.4616 53.3195 15.0711 52.9289C14.6805 52.5384 14.0474 52.5384 13.6569 52.9289L8 58.5858L2.34315 52.9289C1.95262 52.5384 1.31946 52.5384 0.928932 52.9289C0.538408 53.3195 0.538408 53.9526 0.928932 54.3431L7.29289 60.7071ZM7 0L7 60H9L9 0L7 0Z"
  //       fill="white"
  //     />
  //   </svg>
  // );
};

export default Arrow;
